import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';

import type Activity from './activity';
import type Task from './task';

export default class AreaModel extends Model {
  @attr('string') declare name: string;

  @hasMany('activity', { async: false, inverse: 'areas' }) declare activities: SyncHasMany<Activity>;

  @hasMany('task', { async: false, inverse: 'areas' }) declare tasks: SyncHasMany<Task>;

  get heading1() {
    return this.name;
  }
}
