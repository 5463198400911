import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type CurrentUserService from 'solomon-next-reporting-ui/services/current-user';
import type SessionService from 'solomon-next-reporting-ui/services/session';

type Error = {
  message: string;
};

interface ApiError extends Error {
  code: string;
  detail: string;
  source: {};
  status: number;
  title: string;
}

interface JsonApiError extends Error {
  status: number;
  errors: ApiError[];
}

function isJsonApiError(error: unknown): error is JsonApiError {
  return (error as JsonApiError).errors !== undefined && Array.isArray((error as JsonApiError).errors);
}

export default class ApplicationRoute extends Route {
  @service declare session: SessionService;
  @service declare currentUser: CurrentUserService;

  async beforeModel() {
    await this.session.setup();

    if (!this.session.isAuthenticated) {
      return this.session.invalidate();
    }

    try {
      await this.currentUser.userRecord.retry();
    } catch (error: unknown) {
      if (isJsonApiError(error)) {
        for (const err of error.errors) {
          if (err.status === 401) {
            return this.session.invalidate();
          }
        }
      }

      if (!this.currentUser.userId) {
        return this.session.invalidate();
      }

      if (this.currentUser.userRecord.isError) {
        const err = this.currentUser.userRecord.error;

        if ((err?.message || '').includes('401')) {
          return this.session.invalidate();
        }

        console.error(err);
      }

      alert(
        `There was a problem loading your data:\n ${
          (error as Error).message
        }\n\nIf the problem persists please contact Solomon Support team`,
      );
    }
  }
}
