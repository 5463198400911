import Model, { attr, /* type AsyncHasMany, */ hasMany, type SyncHasMany } from '@ember-data/model';

import ActivityTypes from './activity';

import type { StringArray } from './../transforms/string-array';
import type Activity from './activity';

export default class ActivitySubtypeModel extends Model {
  @attr('string') declare title: string;
  @attr({ enumOptions: ActivityTypes, defaultValue: 'interaction' }) declare type: string;
  @attr('string') declare description?: string;
  @attr('string-array') declare reportEmails?: StringArray;
  @attr('number') followUpInterval?: number;
  @attr('number') defaultCost?: number;
  @attr('number') defaultValue?: number;

  @hasMany('activity', { async: false, inverse: 'activitySubtype' }) declare activities?: SyncHasMany<Activity>;

  get heading1() {
    return this.title;
  }

  get heading2() {
    return this.type;
  }
}
