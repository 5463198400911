import Transform from '@ember-data/serializer/transform';

import type Deserialized from '@ember-data/serializer/transform';
import type Serialized from '@ember-data/serializer/transform';

export type FileArray = string[];

export default class FileArrayTransform extends Transform {
  deserialize(serialized: Serialized) {
    return serialized as unknown as FileArray;
  }

  serialize(deserialized: Deserialized) {
    return deserialized;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-array': FileArray;
  }
}
