import EmberRouter from '@embroider/router';

import config from 'solomon-next-reporting-ui/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('authenticated', { path: '' }, function () {
    this.route('tasks');
  });
  this.route('not-found', { path: '/*path' });
});
