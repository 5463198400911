export class HttpError extends Error {
  status?: number;
  detail?: string;

  constructor(message?: string, status?: number, detail?: string) {
    super(message);
    this.status = status;
    this.detail = detail;
  }
}

/**
 * Parses an HTTP response error and returns an object with the error details.
 * @param {any} responseError - The HTTP response error to parse.
 * @returns {HttpError} - An object with the error details.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseHttpResponseError(responseError: any): HttpError {
  if (responseError.errors && Array.isArray(responseError.errors)) {
    // If the response error is an array of errors, convert it to a single error object
    const error: HttpError = {
      name: 'report creation error',
      message: '',
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    responseError.errors.forEach((err: any) => {
      if (err.title && err.detail && err.source) {
        // If the error looks like a JSON:API error from v3 API, extract the error details
        const pointer = err.source.pointer.split('/').pop() as string;
        const detail = `${err.title}: ${pointer} ${err.detail}`;

        // Add the error details to the error object
        if (error.status === undefined && err.status) {
          error.status = err.status as number;
        }

        error.detail = `${error.detail || ''}\n${detail}`;
      }
    });

    return error;
  } else {
    // If the response error is not an array of errors, return it as is
    return responseError as HttpError;
  }
}
