import Service from '@ember/service';
import { inject as service } from '@ember/service';

import { findRecord } from 'ember-data-resources';

import type Collaborator from './../models/collaborator';
import type SessionService from 'solomon-next-reporting-ui/services/session';

export default class CurrentUserService extends Service {
  @service declare session: SessionService;

  userRecord = findRecord<Collaborator>(this, 'collaborator', () => this.userId);

  get userId() {
    return this.session?.data?.authenticated?.userId;
  }

  get user() {
    return this.userRecord.record;
  }
}
