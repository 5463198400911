import Model, { type AsyncHasMany, attr, hasMany, type SyncHasMany } from '@ember-data/model';

import { startCase } from 'lodash';

import type { Location } from '../transforms/location';
import type { FileArray } from './../transforms/file-array';
import type Activity from './activity';
import type Area from './area';
import type Business from './business';
import type Collaborator from './collaborator';

export type TaskStatus = 'pending' | 'to-do' | 'in-progress' | 'done' | 'cancelled';

export enum StatusOptions {
  'pending',
  'to-do',
  'in-progress',
  'done',
  'cancelled',
}

export default class TaskModel extends Model {
  @attr('string') declare title: string;
  @attr({ enumOptions: StatusOptions, defaultValue: 'pending' }) declare status: TaskStatus;
  @attr('string') declare description?: string;
  @attr('number') declare cost?: Number;
  @attr('number') declare value?: Number;
  @attr('date') declare dueDate?: Date;
  @attr('date') declare completionDate?: Date;
  @attr('number') declare progress?: Number;
  @attr('file-array') declare images?: FileArray;
  @attr('location') declare location?: Location;

  @hasMany('activity', { async: true, inverse: 'tasks' }) declare activities?: AsyncHasMany<Activity>;
  @hasMany('area', { async: true, inverse: 'tasks' }) declare areas?: AsyncHasMany<Area>;
  @hasMany('business', { async: true, inverse: 'tasks' }) declare businesses?: AsyncHasMany<Business>;
  @hasMany('collaborator', { async: false, inverse: 'tasks' }) declare collaborators?: SyncHasMany<Collaborator>;

  get heading1() {
    return this.title;
  }

  get heading2() {
    return startCase(this.status);
  }
}
