import { assert } from '@ember/debug';
import Evented from '@ember/object/evented';
import Service from '@ember/service';

export default class GeoLocationService extends Service.extend(Evented) {
  declare watcherId: number | null;

  declare currentLocation: [number, number] | null;

  trackingCallback?: (position: GeolocationPosition) => void;

  get geolocator(): Geolocation {
    return window.navigator.geolocation;
  }

  _handleNewPosition(geoObject: GeolocationPosition) {
    this.currentLocation = [geoObject.coords.latitude, geoObject.coords.longitude];

    const callback = this.trackingCallback;

    if (callback) {
      callback(geoObject);
    }

    this.trigger('geolocationSuccess', geoObject);
  }

  /**
   * Returns a promise that resolves to a geolocation object. The geolocation
   * object contains information about the client's location. The promise
   * resolves immediately if the client's location is already known.
   *
   * @param geoOptions a set of options that can be used to customize the geolocation request
   * @return a promise that resolves to a geolocation object
   */
  getLocation(geoOptions?: PositionOptions): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      this.geolocator.getCurrentPosition(
        (geoObject: GeolocationPosition) => {
          this._handleNewPosition(geoObject);
          resolve(geoObject);
        },
        (reason) => {
          this.trigger('geolocationFail', reason);
          reject(reason);
        },
        geoOptions,
      );
    });
  }

  /**
   * This function is used to track the user's location.
   * It uses the geolocator library to track the user's location.
   * The callback function is called every time the user's location is updated.
   * The watcherId is used to identify the location tracker.
   * @param geoOptions Options for the geolocation.
   * @param callback Callback function that is called every time the user's location is updated.
   * @returns A promise that resolves when the location tracker has been set.
   */
  trackLocation(geoOptions?: PositionOptions, callback?: (position: GeolocationPosition) => void) {
    let watcherId = this.watcherId;

    assert('Warning: `trackLocation` was called but a tracker is already set', watcherId == null);

    if (callback != null) {
      assert('callback should be a function', typeof callback === 'function');
    }

    this.trackingCallback = callback;

    return new Promise((resolve, reject) => {
      watcherId = this.geolocator.watchPosition(
        (geoObject) => {
          resolve(geoObject);
          this._handleNewPosition(geoObject);
        },
        (reason) => {
          this.trigger('geolocationFail', reason);
          reject(reason);
        },
        geoOptions,
      );
      this.watcherId = watcherId;
    });
  }

  stopTracking(clearLocation?: boolean) {
    let watcher = this.watcherId;

    assert("Warning: `stopTracking` was called but location isn't tracked", watcher != null);
    this.geolocator.clearWatch(watcher);
    this.watcherId = null;

    if (clearLocation === true) {
      this.currentLocation = null;
    }
  }
}
