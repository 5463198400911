import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { InvalidError } from '@ember-data/adapter/error';
import JSONAPIAdapter from '@ember-data/adapter/json-api';

import { pluralize } from 'ember-inflector';

import type SessionService from 'solomon-next-reporting-ui/services/session';
import type SolomonConfigService from 'solomon-next-reporting-ui/services/solomon-config';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service declare session: SessionService;
  @service declare solomonConfig: SolomonConfigService;

  namespace = 'api';

  get host(): string {
    const config = this.solomonConfig.config;

    return String(config.apis.solomonApi.host);
  }

  pathForType(modelName: string | number): string {
    const name = String(modelName);

    return pluralize(name.includes('-') ? camelize(name) : name);
  }

  get headers(): {} {
    const headers: Record<string, unknown> = {};

    if (this.session.isAuthenticated) {
      const { id: access_token } = this.session.data.authenticated;

      headers['Authorization'] = access_token;
    }

    return headers;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleResponse(status: number, headers: Headers, payload: any, requestData: { method: string; url: string }) {
    if (this.isSuccess(status, headers, payload)) {
      return payload;
    } else if (this.isInvalid(status, headers, payload)) {
      // eslint-disable-next-line no-debugger
      debugger;

      // TODO: if 401 or 403, invalidate session
      if (status === 401 || status === 403) {
        this.session.invalidate();
      }

      return new InvalidError(typeof payload === 'object' && 'errors' in payload ? payload.errors : undefined);
    }

    return super.handleResponse(status, headers, payload, requestData);
  }
}
