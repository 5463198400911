import fetch from 'fetch';

export async function makeRequest(url: string, data: unknown, accessToken?: string) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Data-Type': 'json',
      ...(accessToken ? { Authorization: accessToken } : {}),
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
