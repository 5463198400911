import Model, { attr } from '@ember-data/model';

export default class TagModel extends Model {
  @attr('date') declare createdAt: Date;
  @attr('string') declare name: string;
  @attr('string') declare description?: string;

  get heading1() {
    return this.name;
  }

  get heading2() {
    return this.description;
  }
}
