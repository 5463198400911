import { solomonConfig } from 'solomon-next-reporting-ui/services/solomon-config';
import { makeRequest } from 'solomon-next-reporting-ui/utils/requests';

import type ActivityModel from 'solomon-next-reporting-ui/models/activity';

export type EmbeddedImage = {
  filename: string;
  content: string; // a bas64 encoded string
  type?: string;
};

export type EmailData = string | { name?: string; email: string };

export type EmailRequestBody = {
  from?: EmailData;
  to: EmailData | EmailData[];
  cc?: EmailData | EmailData[];
  subject: string;
  html: string;
  azureImages?: string[];
  embeddedImages?: EmbeddedImage[];
};

export async function sendEmail(emailData: EmailRequestBody, accessToken: string): Promise<boolean> {
  // Get the host for the email API
  const host = solomonConfig.apis.functionsApi.host;

  // Send the email using the email API
  const response = await makeRequest(`${host}/api/send-mail`, emailData, accessToken);

  // If the email was not sent successfully, throw an error
  if (!response.success) {
    throw new Error(response.statusText);
  }

  // Return true to indicate that the email was sent successfully
  return true;
}

export function reportEmail(activity: ActivityModel, mapImage: string) {
  // Get the subtype of the activity
  const subtype = activity.activitySubtype;

  // Get the email addresses to send the report to
  const toEmails = (subtype?.reportEmails || []).map((e) => e.trim()).filter(Boolean) as EmailData[];

  // If there are no email addresses, return false
  if (!toEmails || toEmails?.length === 0) {
    return false;
  }

  // Get the email addresses to CC the report to
  const ccEmails = (activity.collaborators?.toArray() || []).map((c) => (c.email || '').trim()).filter(Boolean);

  // Set the "from" email address
  const from = ccEmails.length > 0 ? ccEmails[0] : solomonConfig.mail.defaultFromEmail;

  // Set the email subject
  const subject = `${solomonConfig.customerFriendlyName} Reporting ${subtype.title}`;

  // Get the images attached to the activity
  const images: string[] = activity.images || [];

  const imageWidth = '400';

  const businesses =
    (activity?.businesses?.length as number) > 0
      ? `<p><span style="font-weight:bold;">Businesses:</span>
        ${(activity.businesses || [])
          .map((b) => `${b.heading1} ${b.heading2 ? `(${b.heading2})` : ''}`)
          .join('<br>')}</p>`
      : '';

  const areas =
    (activity?.areas?.length as number) > 0
      ? `<p><span style="font-weight:bold;">Areas:</span> ${(activity.areas || [])
          .map((a) => a.heading1)
          .join(', ')}</p>`
      : '';

  // Build the HTML content of the email
  const html = `
    <style type="text/css">
    .imageWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 10px;
    }

    .imageWrapper img {
      width: ${imageWidth}px;
      height: auto;
      margin: 10px;
      object-fit: contain;
    }
    </style>

    <div style="margin: 20px; font-family: sans-serif;">
      <p><span style="font-weight:bold;">This is an email generated from ${
        solomonConfig.customerFriendlyName
      } to inform you of a reported issue.</span></p>
      <p><span style="font-weight:bold;">Issue:</span> ${subtype.title} - ${activity.description}</p>
      ${
        activity.location
          ? `<p><span style="font-weight:bold;">Location:</span>
        <a href="https://www.google.com/maps/search/?api=1&query=${activity.location.lat},${activity.location.lng}">View Google Map</a>
      </p>`
          : ''
      }

      ${businesses}

      ${areas}

      <p><span style="font-weight:bold;">Time of report:</span> ${activity.timestamp}</p>
      <div class="imageWrapper">
        ${images.length > 0 ? `${images.map((i) => `<img src="cid:${i}" width="${imageWidth}" />`).join('\n')}` : ''}
        <img src="cid:mapImage" width="${imageWidth}" />
      </div>
    </div>
    `;

  // Set the data for the email
  const emailData: EmailRequestBody = {
    to: toEmails,
    subject,
    html,
    from,
    azureImages: images,
    embeddedImages: [{ filename: 'mapImage', content: mapImage, type: 'image/jpeg' }],
  };

  return emailData;
}
