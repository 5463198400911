import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';

import type Activity from './activity';
import type Business from './business';
import type Task from './task';

export enum RoleTypes {
  'admin',
  'contributor',
}

export default class CollaboratorModel extends Model {
  @attr({ enumOptions: RoleTypes, defaultValue: 'contributor' }) declare type: string;
  @attr('string') declare firstname: string;
  @attr('string') declare surname: string;
  @attr('string') declare email: string;
  @attr('string') declare jobTitle: string;

  @hasMany('activity', { async: false, inverse: 'collaborators' }) declare activities?: SyncHasMany<Activity>;
  @hasMany('business', { async: false, inverse: 'collaborators' }) declare businesses?: SyncHasMany<Business>;
  @hasMany('task', { async: false, inverse: 'collaborators' }) declare tasks?: SyncHasMany<Task>;

  get name() {
    return [this.firstname, this.surname].filter(Boolean).join(' ');
  }

  get heading1() {
    return this.name || this.email;
  }

  get heading2() {
    return this.jobTitle;
  }

  get initials() {
    return this.name
      .split(' ')
      .map((word) => word[0])
      .join('');
  }
}
