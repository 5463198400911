import { isArray } from '@ember/array';
import JSONAPISerializer from '@ember-data/serializer/json-api';

import { singularize } from 'ember-inflector';

export default class ApplicationSerializer extends JSONAPISerializer {
  normalizeResponse(store, primaryModelClass, payload) {
    if (isArray(payload.data)) {
      payload.data.forEach(function (item) {
        item.type = singularize(item.type);
        // delete item.relationships;
      });
    }

    return super.normalizeResponse(...arguments);
  }

  keyForAttribute(key) {
    return key;
  }

  keyForRelationship(key) {
    return key;
  }
}
