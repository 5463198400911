
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("solomon-next-reporting-ui/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("solomon-next-reporting-ui/adapters/application", function(){ return i("../adapters/application.ts");});
d("solomon-next-reporting-ui/app", function(){ return i("../app.ts");});
d("solomon-next-reporting-ui/authenticators/application", function(){ return i("../authenticators/application.ts");});
d("solomon-next-reporting-ui/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("solomon-next-reporting-ui/config/environment", function(){ return i("../config/environment.js");});
d("solomon-next-reporting-ui/data-adapter", function(){ return i("../data-adapter.js");});
d("solomon-next-reporting-ui/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("solomon-next-reporting-ui/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("solomon-next-reporting-ui/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("solomon-next-reporting-ui/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("solomon-next-reporting-ui/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("solomon-next-reporting-ui/initializers/leaflet-assets", function(){ return i("../initializers/leaflet-assets.js");});
d("solomon-next-reporting-ui/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("solomon-next-reporting-ui/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("solomon-next-reporting-ui/models/activity-subtype", function(){ return i("../models/activity-subtype.ts");});
d("solomon-next-reporting-ui/models/activity", function(){ return i("../models/activity.ts");});
d("solomon-next-reporting-ui/models/area", function(){ return i("../models/area.ts");});
d("solomon-next-reporting-ui/models/business", function(){ return i("../models/business.ts");});
d("solomon-next-reporting-ui/models/collaborator", function(){ return i("../models/collaborator.ts");});
d("solomon-next-reporting-ui/models/tag", function(){ return i("../models/tag.ts");});
d("solomon-next-reporting-ui/models/task", function(){ return i("../models/task.ts");});
d("solomon-next-reporting-ui/router", function(){ return i("../router.ts");});
d("solomon-next-reporting-ui/serializers/-default", function(){ return i("../serializers/-default.js");});
d("solomon-next-reporting-ui/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("solomon-next-reporting-ui/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("solomon-next-reporting-ui/serializers/application", function(){ return i("../serializers/application.js");});
d("solomon-next-reporting-ui/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("solomon-next-reporting-ui/services/cookies", function(){ return i("../services/cookies.js");});
d("solomon-next-reporting-ui/services/current-user", function(){ return i("../services/current-user.ts");});
d("solomon-next-reporting-ui/services/dialog-stack-provider", function(){ return i("../services/dialog-stack-provider.js");});
d("solomon-next-reporting-ui/services/ember-leaflet", function(){ return i("../services/ember-leaflet.js");});
d("solomon-next-reporting-ui/services/file-queue", function(){ return i("../services/file-queue.js");});
d("solomon-next-reporting-ui/services/geo-location", function(){ return i("../services/geo-location.ts");});
d("solomon-next-reporting-ui/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("solomon-next-reporting-ui/services/page-title", function(){ return i("../services/page-title.js");});
d("solomon-next-reporting-ui/services/session", function(){ return i("../services/session.js");});
d("solomon-next-reporting-ui/services/session", function(){ return i("../services/session.ts");});
d("solomon-next-reporting-ui/services/solomon-config", function(){ return i("../services/solomon-config.ts");});
d("solomon-next-reporting-ui/services/store", function(){ return i("../services/store.js");});
d("solomon-next-reporting-ui/services/text-measurer", function(){ return i("../services/text-measurer.js");});
d("solomon-next-reporting-ui/session-stores/application", function(){ return i("../session-stores/application.js");});
d("solomon-next-reporting-ui/session-stores/application", function(){ return i("../session-stores/application.ts");});
d("solomon-next-reporting-ui/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("solomon-next-reporting-ui/transforms/date", function(){ return i("../transforms/date.js");});
d("solomon-next-reporting-ui/transforms/file-array", function(){ return i("../transforms/file-array.ts");});
d("solomon-next-reporting-ui/transforms/location", function(){ return i("../transforms/location.ts");});
d("solomon-next-reporting-ui/transforms/number", function(){ return i("../transforms/number.js");});
d("solomon-next-reporting-ui/transforms/string-array", function(){ return i("../transforms/string-array.ts");});
d("solomon-next-reporting-ui/transforms/string", function(){ return i("../transforms/string.js");});
d("solomon-next-reporting-ui/utils/calculate-position", function(){ return i("../utils/calculate-position.js");});
d("solomon-next-reporting-ui/utils/emails", function(){ return i("../utils/emails.ts");});
d("solomon-next-reporting-ui/utils/errors", function(){ return i("../utils/errors.ts");});
d("solomon-next-reporting-ui/utils/requests", function(){ return i("../utils/requests.ts");});
d("solomon-next-reporting-ui/templates/application", function(){ return i("../templates/application.hbs");});
d("solomon-next-reporting-ui/routes/application", function(){ return i("../routes/application.ts");});
d("solomon-next-reporting-ui/templates/not-found", function(){ return i("../templates/not-found.hbs");});
d("solomon-next-reporting-ui/routes/not-found", function(){ return i("../routes/not-found.ts");});



w._embroiderRouteBundles_ = [
  {
    names: ["authenticated.index"],
    load: function() {
      return import("./_route_/authenticated.index.js");
    }
  },
  {
    names: ["authenticated.tasks"],
    load: function() {
      return import("./_route_/authenticated.tasks.js");
    }
  },
  {
    names: ["authenticated"],
    load: function() {
      return import("./_route_/authenticated.js");
    }
  },
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"config":{"apis":{"solomonApi":{"host":"https://solomon-v3-api-demo.azurewebsites.net"},"functionsApi":{"host":"https://solomon-next-functions.azurewebsites.net"}},"defaultLocation":{"lat":53.8847436,"lng":-1.2663136},"mail":{"defaultFromEmail":"noreply@mysolomon.co.uk","defaultImageSize":600},"customer":"Solomon","customerFriendlyName":"Solomon BID"},"name":"solomon-next-reporting-ui","version":"0.0.0+6053e2d7"});
}

