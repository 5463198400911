import Service from '@ember/service';

import ENV from 'solomon-next-reporting-ui/config/environment';

const solomonConfig = ENV.APP['config'];

export default class SolomonConfigService extends Service {
  get config(): SolomonConfig {
    return solomonConfig;
  }
}

export { solomonConfig };

export interface SolomonConfig {
  apis: {
    solomonApi: {
      host: string;
    };
    functionsApi: {
      host: string;
    };
  };
  defaultLocation: {
    lat: number;
    lng: number;
  };
  mail: {
    defaultFromEmail: string;
    defaultImageSize: number;
  };
  customer: string;
  customerFriendlyName: string;
}
