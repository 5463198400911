import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';

import type { Location } from '../transforms/location';
import type { FileArray } from './../transforms/file-array';
import type ActivitySubtype from './activity-subtype';
import type Area from './area';
import type Business from './business';
import type Collaborator from './collaborator';
import type Tag from './tag';
import type Task from './task';

export enum ActivityTypes {
  achievement,
  benefit,
  crimeReduction,
  event,
  interaction,
  job,
  promotion,
  report,
  training,
}

export default class ActivityModel extends Model {
  @attr('string') declare title: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare timestamp: Date;
  @attr({ enumOptions: ActivityTypes, defaultValue: 'interaction' }) declare type: string;
  @attr('string') declare description?: string;
  @attr('number') declare cost?: Number;
  @attr('number') declare value?: Number;
  @attr('location') declare location?: Location;
  @attr('file-array') declare images?: FileArray;
  @attr('string') declare mapImage?: string;

  @belongsTo('activity-subtype', { async: false, inverse: 'activities' }) declare activitySubtype: ActivitySubtype;

  @hasMany('area', { async: false, inverse: 'activities' }) declare areas?: SyncHasMany<Area>;
  @hasMany('business', { async: false, inverse: 'activities' }) declare businesses?: SyncHasMany<Business>;
  @hasMany('collaborator', { async: false, inverse: 'activities' }) declare collaborators?: SyncHasMany<Collaborator>;
  @hasMany('tag', { async: false, inverse: null }) declare tags: SyncHasMany<Tag>;
  @hasMany('task', { async: false, inverse: 'activities' }) declare tasks?: SyncHasMany<Task>;

  get heading1() {
    return this.title;
  }

  get heading2() {
    return this.activitySubtype.title;
  }
}
