import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';

import { startCase } from 'lodash';

import type Activity from './activity';
import type Collaborator from './collaborator';
import type Task from './task';

export default class BusinessModel extends Model {
  @attr('string') declare tradingName: string;
  @attr('string') declare address: string;

  @hasMany('activity', { async: false, inverse: 'businesses' }) declare activities?: SyncHasMany<Activity>;
  @hasMany('collaborator', { async: false, inverse: 'businesses' }) declare collaborators?: SyncHasMany<Collaborator>;
  @hasMany('task', { async: false, inverse: 'businesses' }) declare tasks?: SyncHasMany<Task>;

  get heading1() {
    return this.tradingName;
  }

  get heading2() {
    return startCase(this.address);
  }
}
