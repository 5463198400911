import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import { makeRequest } from 'solomon-next-reporting-ui/utils/requests';

import type StoreService from '@ember-data/store';
import type CurrentUserService from 'solomon-next-reporting-ui/services/current-user';
import type SessionService from 'solomon-next-reporting-ui/services/session';
import type SolomonConfigService from 'solomon-next-reporting-ui/services/solomon-config';

export default class ApplicationAuthenticator extends BaseAuthenticator {
  @service declare solomonConfig: SolomonConfigService;

  @service declare currentUser: CurrentUserService;

  @service declare session: SessionService;

  @service declare store: StoreService;

  restore(data: Data) {
    return new Promise((resolve, reject) => {
      if (isEmpty(data['id'])) {
        reject();
      } else {
        resolve(data);
      }
    });
  }

  async authenticate(email: string, password: string) {
    const data = { email, password };

    const host = this.solomonConfig.config.apis.solomonApi.host;

    const response = await makeRequest(`${host}/api/collaborators/login`, data);

    if (!response.id || !response.userId) {
      throw new Error(response.statusText);
    }

    return response;
  }
}
