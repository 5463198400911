import Transform from '@ember-data/serializer/transform';

import type Serialized from '@ember-data/serializer/transform';
import type Deserialized from '@ember-data/serializer/transform';

export type Location = {
  lat: number;
  lng: number;
};

export default class LocationTransform extends Transform {
  deserialize(serialized: Serialized): Location {
    return serialized as unknown as Location;
  }

  serialize(deserialized: Deserialized) {
    return deserialized;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    location: Location;
  }
}
