import { inject as service } from '@ember/service';

import Service from 'ember-simple-auth/services/session';
export interface AuthenticatedData {
  id: string;
  ttl: number;
  created: string;
  userId: string;
  principalType: string;
}

import type CurrentUserService from 'solomon-next-reporting-ui/services/current-user';

export default class SessionService extends Service<AuthenticatedData> {
  @service declare currentUser: CurrentUserService;

  handleAuthentication() {
    super.handleAuthentication('authenticated.index');
  }

  handleInvalidation() {
    super.handleAuthentication('login');
  }

  async invalidate() {
    const keysToPersist: string[] = [];

    for (const key of Object.keys(localStorage)) {
      if (!keysToPersist.includes(key.toLowerCase())) {
        localStorage.removeItem(key);
      }
    }

    super.invalidate();
  }
}
