import Transform from '@ember-data/serializer/transform';

import type Deserialized from '@ember-data/serializer/transform';
import type Serialized from '@ember-data/serializer/transform';

export type StringArray = string[];

export default class StringArrayTransform extends Transform {
  deserialize(serialized: Serialized) {
    return serialized as unknown as StringArray;
  }

  serialize(deserialized: Deserialized) {
    return deserialized;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'string-array': StringArray;
  }
}
